import axios from 'axios';
import qs from 'qs';

const API = 'https://ydtmch9j99.execute-api.us-east-1.amazonaws.com/dev/api/';
// const API = 'http://localhost:3000/api/';

class KontenService {
    getKonten(konten) {
        return axios.get(API + `konten/${konten}`)
    }
    getDetail(konten, nama) {
        return axios.get(API + `konten/${konten}/${nama}`)
    }
    getLimit(konten, data, limit) {
        // console.log(data.length)
        // console.log(!Array.isArray(data));
        if(!Array.isArray(data)) {
            data = Array.from(String(data), Number);
        }
        if (data.length == 0) {
            return axios.get(API + `limit/${konten}`, {
                params: {
                    id: "all",
                    limit: limit
                }
            })
        } else {
            return axios.get(API + `limit/${konten}`, {
                params: {
                    id: data,
                    limit: limit
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            })
        }
        
        // return axios.get(API + `limit/${konten}/${id}/${limit}`)
    }
    getLatest(konten, limit) {
        return axios.get(API + `latest/${konten}/${limit}`)
    }
}

export default new KontenService();